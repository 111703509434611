define("discourse/plugins/discourse-rewind/discourse/components/reports/favorite-categories", ["exports", "@ember/helper", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FavoriteCategories = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @report.data.length}}
      <div class="rewind-report-page -favorite-categories">
        <h2 class="rewind-report-title">{{i18n
            "discourse_rewind.reports.favorite_categories.title"
            count=@report.data.length
          }}</h2>
        <div class="rewind-report-container">
          {{#each @report.data as |data|}}
            <a href={{concat "/c/-/" data.category_id}} class="rewind-card">
              <p
                class="favorite-categories__category"
                href={{concat "/c/-/" data.category_id}}
              >{{data.name}}</p>
            </a>
          {{/each}}
        </div>
      </div>
    {{/if}}
  
  */
  {
    "id": "6oUDUqd/",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"data\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"rewind-report-page -favorite-categories\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.favorite_categories.title\"],[[\"count\"],[[30,1,[\"data\",\"length\"]]]]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"rewind-report-container\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"          \"],[10,3],[15,6,[28,[32,1],[\"/c/-/\",[30,2,[\"category_id\"]]],null]],[14,0,\"rewind-card\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"favorite-categories__category\"],[15,6,[28,[32,1],[\"/c/-/\",[30,2,[\"category_id\"]]],null]],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@report\",\"data\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/favorite-categories.js",
    "scope": () => [_discourseI18n.i18n, _helper.concat],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "favorite-categories:FavoriteCategories"));
  var _default = _exports.default = FavoriteCategories;
});