define("discourse/plugins/discourse-rewind/discourse/components/reports/top-words/word-card", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/template", "discourse/helpers/concat-class", "discourse/helpers/emoji", "discourse-common/lib/later", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _didInsert, _template, _concatClass, _emoji, _later, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MYSTERY_EMOJIS = ["mag", "question", "8ball", "crystal_ball", "crescent_moon"];
  const BACKGROUND_COLORS = [["251, 245, 175", "40, 171, 226", "12, 166, 78", "240, 121, 74", "232, 74, 81"], ["197, 193, 140", "39, 137, 178", "17, 138, 68", "188, 105, 65", "183, 64, 70"]];
  class WordCard extends _component.default {
    get randomStyle() {
      return `--rand: ${Math.random()}`;
    }
    get mysteryData() {
      return {
        emoji: MYSTERY_EMOJIS[this.args.index],
        color: `--mystery-color-light: ${BACKGROUND_COLORS[0][this.args.index]}; --mystery-color-dark: ${BACKGROUND_COLORS[1][this.args.index]};`
      };
    }
    get longWord() {
      return this.args.word.length >= 5;
    }
    get cardStyle() {
      return (0, _template.htmlSafe)(`${this.randomStyle}; ${this.mysteryData.color};`);
    }
    registerCardContainer(element) {
      this.cardContainer = element;
    }
    static #_ = (() => dt7948.n(this.prototype, "registerCardContainer", [_object.action]))();
    handleClick() {
      this.cardContainer.classList.toggle("flipped");
    }
    static #_2 = (() => dt7948.n(this.prototype, "handleClick", [_object.action]))();
    handleLeave() {
      const cardContainer = this.cardContainer;
      cardContainer.classList.toggle("mouseleave");
      (0, _later.default)(() => {
        cardContainer.classList.remove("mouseleave");
      }, 100);
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleLeave", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          {{on "click" this.handleClick}}
          {{on "mouseleave" this.handleLeave}}
          class={{concatClass
            "rewind-card__wrapper"
            (if this.longWord "-long-word")
          }}
          style={{this.cardStyle}}
          {{didInsert this.registerCardContainer}}
          role="button"
        >
          <div class="rewind-card__inner">
            <div class="rewind-card -front">
              <span class="rewind-card__image tl">{{emoji
                  this.mysteryData.emoji
                }}</span>
              <span class="rewind-card__image cr">{{emoji
                  this.mysteryData.emoji
                }}</span>
              <span class="rewind-card__image br">{{emoji
                  this.mysteryData.emoji
                }}</span>
            </div>
            <div class="rewind-card -back">
              <span class="rewind-card__title">{{@word}}</span>
              <span class="rewind-card__data">{{@count}}x</span>
            </div>
          </div>
        </div>
      
    */
    {
      "id": "X27PDHC8",
      "block": "[[[1,\"\\n    \"],[11,0],[16,0,[28,[32,0],[\"rewind-card__wrapper\",[52,[30,0,[\"longWord\"]],\"-long-word\"]],null]],[16,5,[30,0,[\"cardStyle\"]]],[24,\"role\",\"button\"],[4,[32,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[32,1],[\"mouseleave\",[30,0,[\"handleLeave\"]]],null],[4,[32,2],[[30,0,[\"registerCardContainer\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"rewind-card__inner\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"rewind-card -front\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"rewind-card__image tl\"],[12],[1,[28,[32,3],[[30,0,[\"mysteryData\",\"emoji\"]]],null]],[13],[1,\"\\n          \"],[10,1],[14,0,\"rewind-card__image cr\"],[12],[1,[28,[32,3],[[30,0,[\"mysteryData\",\"emoji\"]]],null]],[13],[1,\"\\n          \"],[10,1],[14,0,\"rewind-card__image br\"],[12],[1,[28,[32,3],[[30,0,[\"mysteryData\",\"emoji\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"rewind-card -back\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"rewind-card__title\"],[12],[1,[30,1]],[13],[1,\"\\n          \"],[10,1],[14,0,\"rewind-card__data\"],[12],[1,[30,2]],[1,\"x\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@word\",\"@count\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/top-words/word-card.js",
      "scope": () => [_concatClass.default, _modifier.on, _didInsert.default, _emoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WordCard;
});