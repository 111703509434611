define("discourse/plugins/discourse-rewind/discourse/components/reports/best-topics", ["exports", "@glimmer/component", "@ember/helper", "@ember/template", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _template, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BestTopics extends _component.default {
    rank(idx) {
      return idx + 1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @report.data.length}}
          <div class="rewind-report-page -best-topics">
            <h2 class="rewind-report-title">{{i18n
                "discourse_rewind.reports.best_topics.title"
                count=@report.data.length
              }}</h2>
            <div class="rewind-report-container">
              <div class="rewind-card">
                {{#each @report.data as |topic idx|}}
                  <a
                    href={{concat "/t/-/" topic.topic_id}}
                    class={{concat "best-topics__topic" " rank-" (this.rank idx)}}
                  >
                    <span class="best-topics -rank"></span>
                    <span class="best-topics -rank"></span>
                    <h2 class="best-topics__header">{{topic.title}}</h2>
                    <span class="best-topics__excerpt">{{htmlSafe
                        topic.excerpt
                      }}</span>
                  </a>
                {{/each}}
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "rP5QmAX/",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"data\",\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"rewind-report-page -best-topics\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.best_topics.title\"],[[\"count\"],[[30,1,[\"data\",\"length\"]]]]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"rewind-report-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"rewind-card\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"              \"],[10,3],[15,6,[28,[32,1],[\"/t/-/\",[30,2,[\"topic_id\"]]],null]],[15,0,[28,[32,1],[\"best-topics__topic\",\" rank-\",[28,[30,0,[\"rank\"]],[[30,3]],null]],null]],[12],[1,\"\\n                \"],[10,1],[14,0,\"best-topics -rank\"],[12],[13],[1,\"\\n                \"],[10,1],[14,0,\"best-topics -rank\"],[12],[13],[1,\"\\n                \"],[10,\"h2\"],[14,0,\"best-topics__header\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n                \"],[10,1],[14,0,\"best-topics__excerpt\"],[12],[1,[28,[32,2],[[30,2,[\"excerpt\"]]],null]],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@report\",\"topic\",\"idx\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/best-topics.js",
      "scope": () => [_discourseI18n.i18n, _helper.concat, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BestTopics;
});