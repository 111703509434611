define("discourse/plugins/discourse-rewind/discourse/components/reports/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RewindHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="rewind__header">
      <img
        class="rewind-logo -light"
        src="/plugins/discourse-rewind/images/discourse-rewind-logo.png"
      />
      <img
        class="rewind-logo -dark"
        src="/plugins/discourse-rewind/images/discourse-rewind-logo-dark.png"
      />
    </div>
  
  */
  {
    "id": "sC86SYH5",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"rewind__header\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"rewind-logo -light\"],[14,\"src\",\"/plugins/discourse-rewind/images/discourse-rewind-logo.png\"],[12],[13],[1,\"\\n    \"],[10,\"img\"],[14,0,\"rewind-logo -dark\"],[14,\"src\",\"/plugins/discourse-rewind/images/discourse-rewind-logo-dark.png\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/header.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "header:RewindHeader"));
  var _default = _exports.default = RewindHeader;
});