define("discourse/plugins/discourse-rewind/discourse/components/reports/reading-time", ["exports", "@glimmer/component", "@ember/template", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReadingTime extends _component.default {
    get readTimeString() {
      let totalMinutes = Math.floor(this.args.report.data.reading_time / 60);
      let leftOverMinutes = totalMinutes % 60;
      let totalHours = (totalMinutes - leftOverMinutes) / 60;
      if (leftOverMinutes >= 35) {
        totalHours += 1;
        leftOverMinutes = 0;
        return `${totalHours}h`;
      } else {
        return `${totalHours}h ${leftOverMinutes}m`;
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @report.data}}
          <div class="rewind-report-page -reading-time">
            <h2 class="rewind-report-title">{{i18n
                "discourse_rewind.reports.reading_time.title"
              }}</h2>
            <div class="rewind-card">
              <p class="reading-time__text">
                {{htmlSafe
                  (i18n
                    "discourse_rewind.reports.reading_time.book_comparison"
                    readingTitme=this.readTimeString
                    bookTitle=@report.data.book
                  )
                }}
              </p>
              <div class="reading-time__book">
                <div class="book">
                  <img
                    alt=""
                    src="/plugins/discourse-rewind/images/books/{{@report.data.isbn}}.jpg"
                  />
                </div>
                {{#if @report.data.series}}
                  <div class="book-series one"></div>
                  <div class="book-series two"></div>
                  <div class="book-series three"></div>
                {{/if}}
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "TKSYFwbE",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"data\"]],[[[1,\"      \"],[10,0],[14,0,\"rewind-report-page -reading-time\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.reading_time.title\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"rewind-card\"],[12],[1,\"\\n          \"],[10,2],[14,0,\"reading-time__text\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[[28,[32,0],[\"discourse_rewind.reports.reading_time.book_comparison\"],[[\"readingTitme\",\"bookTitle\"],[[30,0,[\"readTimeString\"]],[30,1,[\"data\",\"book\"]]]]]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"reading-time__book\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"book\"],[12],[1,\"\\n              \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[29,[\"/plugins/discourse-rewind/images/books/\",[30,1,[\"data\",\"isbn\"]],\".jpg\"]]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"],[41,[30,1,[\"data\",\"series\"]],[[[1,\"              \"],[10,0],[14,0,\"book-series one\"],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"book-series two\"],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"book-series three\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@report\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/reading-time.js",
      "scope": () => [_discourseI18n.i18n, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReadingTime;
});