define("discourse/plugins/discourse-rewind/discourse/lib/is-rewind-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isRewindActive;
  function isRewindActive() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return currentMonth === 0 || currentMonth === 11;
  }
});