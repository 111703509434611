define("discourse/plugins/discourse-rewind/discourse/rewind-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("user", {
      path: "/u/:username",
      resetNamespace: true
    }, function () {
      this.route("userActivity", function () {
        this.route("rewind");
      });
    });
  }
});