define("discourse/plugins/discourse-rewind/discourse/components/reports/fbff", ["exports", "@ember/helper", "discourse/helpers/bound-avatar-template", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _boundAvatarTemplate, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FBFF = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="rewind-report-page -fbff">
      <h2 class="rewind-report-title">{{i18n
          "discourse_rewind.reports.fbff.title"
        }}</h2>
      <div class="rewind-report-container">
        <div class="rewind-card">
          <div class="fbff-avatar-container">
            {{avatar
              @report.data.fbff.avatar_template
              "huge"
              (hash title=@report.data.fbff.username)
            }}
            <p class="fbff-avatar-name">@{{@report.data.fbff.username}}</p>
          </div>
          <div class="fbff-gif-container">
            <img
              class="fbff-gif"
              src="/plugins/discourse-rewind/images/fbff.gif"
            />
          </div>
          <div class="fbff-avatar-container">
            {{avatar
              @report.data.yourself.avatar_template
              "huge"
              (hash title=@report.data.yourself.username)
            }}
            <p class="fbff-avatar-name">@{{@report.data.yourself.username}}</p>
          </div>
        </div>
      </div>
    </div>
  
  */
  {
    "id": "Xul58gNy",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"rewind-report-page -fbff\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.fbff.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"rewind-report-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"rewind-card\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"fbff-avatar-container\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"data\",\"fbff\",\"avatar_template\"]],\"huge\",[28,[32,2],null,[[\"title\"],[[30,1,[\"data\",\"fbff\",\"username\"]]]]]],null]],[1,\"\\n          \"],[10,2],[14,0,\"fbff-avatar-name\"],[12],[1,\"@\"],[1,[30,1,[\"data\",\"fbff\",\"username\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"fbff-gif-container\"],[12],[1,\"\\n          \"],[10,\"img\"],[14,0,\"fbff-gif\"],[14,\"src\",\"/plugins/discourse-rewind/images/fbff.gif\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"fbff-avatar-container\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"data\",\"yourself\",\"avatar_template\"]],\"huge\",[28,[32,2],null,[[\"title\"],[[30,1,[\"data\",\"yourself\",\"username\"]]]]]],null]],[1,\"\\n          \"],[10,2],[14,0,\"fbff-avatar-name\"],[12],[1,\"@\"],[1,[30,1,[\"data\",\"yourself\",\"username\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@report\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/fbff.js",
    "scope": () => [_discourseI18n.i18n, _boundAvatarTemplate.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "fbff:FBFF"));
  var _default = _exports.default = FBFF;
});