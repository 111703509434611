define("discourse/plugins/discourse-code-review/discourse/initializers/init-code-review", ["exports", "@ember/object", "@ember/template", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/models/login-method", "I18n"], function (_exports, _object, _template, _ajax, _ajaxError, _pluginApi, _url, _loginMethod, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  async function actOnCommit(topic, action) {
    try {
      let result = await (0, _ajax.ajax)(`/code-review/${action}.json`, {
        type: "POST",
        data: {
          topic_id: topic.id
        }
      });
      if (result.next_topic_url) {
        _url.default.routeTo(result.next_topic_url);
      } else {
        _url.default.routeTo("/");
      }
    } catch (error) {
      (0, _ajaxError.popupAjaxError)(error);
    }
  }
  function initialize(api) {
    api.addPostSmallActionIcon("followup", "far-clock");
    api.addPostSmallActionIcon("approved", "thumbs-up");
    api.addPostSmallActionIcon("followed_up", "backward");
    api.addPostSmallActionIcon("pr_merge_info", "info-circle");

    // we need to allow unconditional association even with 2fa
    // core hides this section if 2fa is on for a user
    //
    // note there are slightly cleaner ways of doing this but we would need
    // to amend core for the plugin which is not feeling right
    api.modifyClass("controller:preferences/account", Superclass => class extends Superclass {
      get canUpdateAssociatedAccounts() {
        return (0, _loginMethod.findAll)().length > 0;
      }
      static #_ = (() => dt7948.n(this.prototype, "canUpdateAssociatedAccounts", [(0, _object.computed)("authProviders")]))();
    });
    api.modifyClass("controller:preferences/notifications", Superclass => class extends Superclass {
      init() {
        super.init(...arguments);
        this.saveAttrNames.push("custom_fields");
      }
    });
    function allowSkip(currentUser, topic, siteSettings) {
      return allowApprove(currentUser, topic, siteSettings);
    }
    function allowApprove(currentUser, topic, siteSettings) {
      if (!currentUser) {
        return false;
      }
      const allowSelfApprove = siteSettings.code_review_allow_self_approval;
      const approvedTag = siteSettings.code_review_approved_tag;
      const pendingTag = siteSettings.code_review_pending_tag;
      const followupTag = siteSettings.code_review_followup_tag;
      const tags = topic.tags || [];
      return (allowSelfApprove || currentUser.id !== topic.user_id) && !tags.includes(approvedTag) && (tags.includes(pendingTag) || tags.includes(followupTag));
    }
    function allowFollowupButton(topic, siteSettings) {
      if (!siteSettings.code_review_allow_manual_followup) {
        return false;
      }
      const approvedTag = siteSettings.code_review_approved_tag;
      const pendingTag = siteSettings.code_review_pending_tag;
      const followupTag = siteSettings.code_review_followup_tag;
      const tags = topic.tags || [];
      return !tags.includes(followupTag) && (tags.includes(pendingTag) || tags.includes(approvedTag));
    }
    function allowFollowedUpButton(currentUser, topic, siteSettings) {
      const followupTag = siteSettings.code_review_followup_tag;
      const tags = topic.tags || [];
      return currentUser.id === topic.user_id && tags.includes(followupTag);
    }
    api.registerTopicFooterButton({
      id: "approve",
      icon: "thumbs-up",
      priority: 260,
      label: "code_review.approve.label",
      title: "code_review.approve.title",
      action() {
        actOnCommit(this.topic, "approve");
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["approve"],
      dependentKeys: ["topic.tags"],
      displayed() {
        return this.get("currentUser.can_review_code") && allowApprove(this.currentUser, this.topic, this.siteSettings);
      }
    });
    api.registerTopicFooterButton({
      id: "skip",
      icon: "angles-right",
      priority: 250,
      label: "code_review.skip.label",
      title: "code_review.skip.title",
      action() {
        actOnCommit(this.topic, "skip");
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["skip"],
      dependentKeys: ["topic.tags"],
      displayed() {
        return this.get("currentUser.can_review_code") && allowSkip(this.currentUser, this.topic, this.siteSettings);
      }
    });
    api.registerTopicFooterButton({
      id: "followup",
      icon: "far-clock",
      priority: 240,
      label: "code_review.followup.label",
      title: "code_review.followup.title",
      action() {
        actOnCommit(this.topic, "followup");
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["followup"],
      dependentKeys: ["topic.tags"],
      displayed() {
        return this.get("currentUser.can_review_code") && allowFollowupButton(this.topic, this.siteSettings);
      }
    });
    api.registerTopicFooterButton({
      id: "followed_up",
      icon: "history",
      priority: 240,
      label: "code_review.followed_up.label",
      title: "code_review.followed_up.title",
      action() {
        actOnCommit(this.topic, "followed_up");
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["followup"],
      dependentKeys: ["topic.tags"],
      displayed() {
        return this.get("currentUser.can_review_code") && allowFollowedUpButton(this.currentUser, this.topic, this.siteSettings);
      }
    });
    api.replaceIcon("notification.code_review_commit_approved", "check");
    api.addKeyboardShortcut("y", function () {
      if (!this.currentUser?.can_review_code || !allowApprove(this.currentUser, this.currentTopic(), this.siteSettings)) {
        return;
      }
      actOnCommit(this.currentTopic(), "approve");
    });
    if (api.registerNotificationTypeRenderer) {
      api.registerNotificationTypeRenderer("code_review_commit_approved", NotificationTypeBase => {
        return class extends NotificationTypeBase {
          get linkTitle() {
            return _I18n.default.t("notifications.code_review.commit_approved.title");
          }
          get icon() {
            return "check";
          }
          get linkHref() {
            return super.linkHref || (0, _url.userPath)(`${this.currentUser.username}/activity/approval-given`);
          }
          get label() {
            const numApprovedCommits = this.notification.data.num_approved_commits;
            if (numApprovedCommits > 1) {
              return _I18n.default.t("notifications.code_review.commit_approved.multiple", {
                count: numApprovedCommits
              });
            } else {
              return (0, _template.htmlSafe)(_I18n.default.t("notifications.code_review.commit_approved.single", {
                topicTitle: this.notification.fancy_title
              }));
            }
          }
          get description() {
            return null;
          }
        };
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-code-review",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.28", initialize);
    }
  };
});