define("discourse/plugins/discourse-rewind/discourse/connectors/after-header-panel/rewind-decoration", ["exports", "@glimmer/component", "@ember-compat/tracked-built-ins", "discourse/helpers/body-class", "discourse/lib/key-value-store", "discourse/plugins/discourse-rewind/discourse/lib/is-rewind-active", "@ember/component", "@ember/template-factory"], function (_exports, _component, _trackedBuiltIns, _bodyClass, _keyValueStore, _isRewindActive, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AvatarDecorator extends _component.default {
    store = (() => new _trackedBuiltIns.TrackedObject(new _keyValueStore.default("discourse_rewind_" + this.fetchYear)))();
    get fetchYear() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      if (currentMonth === 0) {
        return currentYear - 1;
      } else {
        return currentYear;
      }
    }
    get dismissed() {
      return this.store.getObject("_dismissed") ?? false;
    }
    get showDecorator() {
      return (0, _isRewindActive.default)() && !this.dismissed;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showDecorator}}
          {{bodyClass "rewind-notification-active"}}
        {{/if}}
      
    */
    {
      "id": "nihQwDhU",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showDecorator\"]],[[[1,\"      \"],[1,[28,[32,0],[\"rewind-notification-active\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/connectors/after-header-panel/rewind-decoration.js",
      "scope": () => [_bodyClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AvatarDecorator;
});