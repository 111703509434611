define("discourse/plugins/discourse-rewind/discourse/components/reports/top-words", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/discourse-rewind/discourse/components/reports/top-words/word-card", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _wordCard, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WordCards extends _component.default {
    get topWords() {
      return this.args.report.data.sort((a, b) => b.score - a.score).slice(0, 5);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="rewind-report-page -top-words">
          <div class="rewind-report-container">
            <h2 class="rewind-report-title">{{i18n
                "discourse_rewind.reports.top_words.title"
              }}</h2>
            <div class="cards-container">
              {{#each this.topWords as |entry index|}}
                <WordCard
                  @word={{entry.word}}
                  @count={{entry.score}}
                  @index={{index}}
                />
              {{/each}}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "IG/0TNtl",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"rewind-report-page -top-words\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"rewind-report-container\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.top_words.title\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"cards-container\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"topWords\"]]],null]],null],null,[[[1,\"            \"],[8,[32,1],null,[[\"@word\",\"@count\",\"@index\"],[[30,1,[\"word\"]],[30,1,[\"score\"]],[30,2]]],null],[1,\"\\n\"]],[1,2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"entry\",\"index\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/top-words.js",
      "scope": () => [_discourseI18n.i18n, _wordCard.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WordCards;
});