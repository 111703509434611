define("discourse/plugins/discourse-rewind/discourse/connectors/user-activity-bottom/rewind-tab", ["exports", "discourse/components/d-navigation-item", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/discourse-rewind/discourse/lib/is-rewind-active", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dNavigationItem, _dIcon, _discourseI18n, _isRewindActive, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RewindTab = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if isRewindActive}}
      <DNavigationItem
        @route="userActivity.rewind"
        @ariaCurrentContext="subNav"
        class="user-nav__activity-rewind"
      >
        {{icon "repeat"}}
        <span>{{i18n "discourse_rewind.title"}}</span>
      </DNavigationItem>
    {{/if}}
  
  */
  {
    "id": "DxX2Kk/P",
    "block": "[[[1,\"\\n\"],[41,[32,0],[[[1,\"    \"],[8,[32,1],[[24,0,\"user-nav__activity-rewind\"]],[[\"@route\",\"@ariaCurrentContext\"],[\"userActivity.rewind\",\"subNav\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[32,2],[\"repeat\"],null]],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,3],[\"discourse_rewind.title\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/connectors/user-activity-bottom/rewind-tab.js",
    "scope": () => [_isRewindActive.default, _dNavigationItem.default, _dIcon.default, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "rewind-tab:RewindTab"));
  var _default = _exports.default = RewindTab;
});