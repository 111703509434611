define("discourse/plugins/discourse-rewind/discourse/components/reports/best-posts", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/template", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _template, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BestPosts extends _component.default {
    rank(idx) {
      return idx + 1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @report.data.length}}
          <div class="rewind-report-page -best-posts">
            <h2 class="rewind-report-title">{{i18n
                "discourse_rewind.reports.best_posts.title"
                count=@report.data.length
              }}</h2>
            <div class="rewind-report-container">
              {{#each @report.data as |post idx|}}
                <div class={{concat "rewind-card" " rank-" (this.rank idx)}}>
                  <span class="best-posts -rank"></span>
                  <span class="best-posts -rank"></span>
                  <div class="best-posts__post">{{htmlSafe (get post "5")}}</div>
                  <div class="best-posts__metadata">
                    <span class="best-posts__likes">
                      {{icon "heart"}}{{htmlSafe (get post "2")}}</span>
                    <span class="best-posts__replies">
                      {{icon "comment"}}{{htmlSafe (get post "3")}}</span>
                    <a href="/t/{{get post '1'}}/{{get post '0'}}">{{i18n
                        "discourse_rewind.reports.best_posts.view_post"
                      }}</a>
                  </div>
                </div>
              {{/each}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "8sptaOAY",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"data\",\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"rewind-report-page -best-posts\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"rewind-report-title\"],[12],[1,[28,[32,0],[\"discourse_rewind.reports.best_posts.title\"],[[\"count\"],[[30,1,[\"data\",\"length\"]]]]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"rewind-report-container\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"            \"],[10,0],[15,0,[28,[32,1],[\"rewind-card\",\" rank-\",[28,[30,0,[\"rank\"]],[[30,3]],null]],null]],[12],[1,\"\\n              \"],[10,1],[14,0,\"best-posts -rank\"],[12],[13],[1,\"\\n              \"],[10,1],[14,0,\"best-posts -rank\"],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"best-posts__post\"],[12],[1,[28,[32,2],[[28,[32,3],[[30,2],\"5\"],null]],null]],[13],[1,\"\\n              \"],[10,0],[14,0,\"best-posts__metadata\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"best-posts__likes\"],[12],[1,\"\\n                  \"],[1,[28,[32,4],[\"heart\"],null]],[1,[28,[32,2],[[28,[32,3],[[30,2],\"2\"],null]],null]],[13],[1,\"\\n                \"],[10,1],[14,0,\"best-posts__replies\"],[12],[1,\"\\n                  \"],[1,[28,[32,4],[\"comment\"],null]],[1,[28,[32,2],[[28,[32,3],[[30,2],\"3\"],null]],null]],[13],[1,\"\\n                \"],[10,3],[15,6,[29,[\"/t/\",[28,[32,3],[[30,2],\"1\"],null],\"/\",[28,[32,3],[[30,2],\"0\"],null]]]],[12],[1,[28,[32,0],[\"discourse_rewind.reports.best_posts.view_post\"],null]],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@report\",\"post\",\"idx\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/components/reports/best-posts.js",
      "scope": () => [_discourseI18n.i18n, _helper.concat, _template.htmlSafe, _object.get, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BestPosts;
});